import Swiper from "swiper";

export default function () {
  const sliders = document.querySelectorAll(".j-slider");
  const sliderLength = sliders.length;
  for (let i = 0; i < sliderLength; i++) {
    const swiper = sliders[i].querySelector(".swiper-container");
    const swiperLength = swiper.querySelectorAll(".swiper-slide").length;
    const isFadeEffect = swiperLength > 1 ? "fade" : false,
      isLoop = swiperLength > 1 ? true : false;

    const mySwiper = new Swiper(swiper, {
      slidesPerView: 1,
      loop: isLoop,
      disableOnInteraction: false,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
      speed: 2000,
      spaceBetween: 0,
      effect: isFadeEffect,
    });
  }
}
