"use strict";

import Tab from "./_tab";
import Slider from "./_slider";

// header
const body = document.querySelector("body"),
  menu = document.querySelector(".j-menu"),
  nav = document.querySelector(".nav");

window.onscroll = () => {
  const y = window.pageYOffset;

  setTimeout(() => {
    if (y > 100) {
      body.classList.add("is-scroll");
    } else {
      body.classList.remove("is-scroll");
    }
  }, 100);
};

if (menu) {
  menu.addEventListener("click", (e) => {
    const _this = e.currentTarget;

    _this.classList.toggle("is-open");
    nav.classList.toggle("is-open");
  });
}

Tab();
Slider();
