export default function () {
  const tabSelector = document.querySelectorAll(".j-tab-selector");

  if (0 < tabSelector.length) {
    for (let i = 0; i < tabSelector.length; i++) {
      const li = tabSelector[i].querySelectorAll("li");
      const tabParent = tabSelector[i].nextElementSibling;
      const tab = tabParent.querySelectorAll(".tab");

      for (let j = 0; j < li.length; j++) {
        li[j].addEventListener("click", (e) => {
          const _this = e.currentTarget;
          const index = [].slice.call(li).indexOf(_this);

          if (_this.classList.contains("is-active")) {
            e.preventDefault;
          } else {
            for (let j = 0; j < li.length; j++) {
              li[j].classList.remove("is-active");
              tab[j].classList.remove("is-active");
            }
            _this.classList.add("is-active");
            tab[index].classList.add("is-active");
          }
        });
      }

      const tabBtn = tabParent.querySelectorAll(".j-tab-btn");

      for (let i = 0; i < tabBtn.length; i++) {
        tabBtn[i].addEventListener("click", (e) => {
          e.preventDefault();

          const _this = e.currentTarget;
          const data = _this.dataset.tabGo;
          const elmY = tabSelector[0].offsetTop;
          const headerH = document.querySelector(".header").clientHeight;
          const top = elmY - headerH;

          for (let j = 0; j < li.length; j++) {
            li[j].classList.remove("is-active");
            tab[j].classList.remove("is-active");
          }
          li[data].classList.add("is-active");
          tab[data].classList.add("is-active");

          window.scroll({
            top: top,
            behavior: "smooth",
          });

          console.log(elmY, scrollY);
        });
      }
    }
  }
}
